import { useEffect, useRef } from 'react';
import { CAROUSEL_IMAGES } from '../constants/images';

const SCROLL_SPEED = 0.5;
const DUPLICATE_COUNT = 3;

export default function Carousel() {
  const scrollRef = useRef<HTMLDivElement>(null);
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();
  const scrollPositionRef = useRef(0);

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      if (scrollRef.current) {
        scrollPositionRef.current -= SCROLL_SPEED;
        
        if (scrollPositionRef.current <= -(scrollRef.current.scrollWidth / DUPLICATE_COUNT)) {
          scrollPositionRef.current = 0;
        }
        
        scrollRef.current.style.transform = `translateX(${scrollPositionRef.current}px)`;
      }
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  const allImages = [...Array(DUPLICATE_COUNT)].flatMap(() => CAROUSEL_IMAGES);

  return (
    <div className="w-full mt-2 sm:mt-4 relative">
      <div className="relative w-full overflow-hidden py-4 sm:py-6">
        <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-transparent to-transparent z-10" />
        <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-transparent to-transparent z-10" />
        
        <div 
          className="flex gap-8 sm:gap-16 px-4 sm:px-8 transition-transform duration-300 ease-linear" 
          ref={scrollRef}
          style={{ willChange: 'transform' }}
        >
          {allImages.map((image, index) => (
            <div
              key={`${image.alt}-${index}`}
              className="flex-shrink-0 transform-gpu"
            >
              <div className="
                relative 
                w-16 h-16
                sm:w-24 sm:h-24 
                lg:w-32 lg:h-32 
                rounded-full 
                border-2 border-white/30 
                overflow-hidden 
                shadow-lg hover:shadow-xl 
                transition-all duration-300
                backdrop-blur-sm
                hover:-translate-y-2
                animate-spin-slow
              ">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full object-cover rounded-full"
                  loading="lazy"
                  decoding="async"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent rounded-full" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}