import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Logo } from '../components/Logo';
import { GlowEffect } from '../components/GlowEffect';
import Carousel from '../components/Carousel';
import { Footer } from '../components/Footer';

export default function PrivacyPage() {
  return (
    <div className="min-h-screen overflow-hidden bg-gradient-to-b from-primary via-primary to-[#003A88]">
      <div className="relative flex flex-col min-h-screen">
        <div className="absolute inset-0 pointer-events-none">
          <GlowEffect />
        </div>
        <div className="container mx-auto px-4 py-4 sm:py-6 flex-grow">
          {/* Header */}
          <div className="flex items-center justify-between mb-6 sm:mb-8">
            <Link 
              to="/"
              className="flex items-center text-white/80 hover:text-white transition-colors gap-2"
            >
              <ArrowLeft size={20} />
              <span>Zurück</span>
            </Link>
          </div>
          {/* Content */}
          <div className="max-w-4xl mx-auto mb-8">
            <div className="backdrop-blur-lg rounded-xl p-4 sm:p-6 lg:p-8 shadow-xl border border-white/10">
                                          {/* Logo positioned at top right of content container */}
              <div className="absolute -top-6 -right-9 w-24 h-24 sm:w-28 sm:h-28">
                <Logo />
              </div>
              <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-white mb-4 sm:mb-6">
                Datenschutzerklärung
              </h1>
              <div className="space-y-6 sm:space-y-8 text-white/90">
                {/* Abschnitt 1: Verantwortliche Stelle und Überblick */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Verantwortliche Stelle und Überblick über die Datenverarbeitung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Verantwortlich für die Datenverarbeitung auf dieser Website ist:
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>AITA Solution - Aymane Amaioua</strong><br />
                      Neugasse 19A<br />
                      65795 Hattersheim am Main<br />
                      Deutschland
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Kontaktmöglichkeiten:<br />
                      E-Mail: <a href="mailto:hallo@aita-solution.de">hallo@aita-solution.de</a><br />
                      Telefon: +49 1575 64 55 908
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Wir sind verpflichtet, Ihre personenbezogenen Daten in Übereinstimmung mit den Anforderungen der Datenschutz-Grundverordnung (DSGVO) und anderer anwendbarer Datenschutzgesetze zu verarbeiten und zu schützen.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 2: Überblick über die Datenverarbeitung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Überblick über die Datenverarbeitung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Bei der Nutzung von qper.ai verarbeiten wir personenbezogene Daten, um Ihnen unsere Dienste bereitzustellen, den sicheren Betrieb der Plattform zu gewährleisten und die Benutzererfahrung zu optimieren. Ihre Daten werden ausschließlich für die Zwecke verwendet, die in dieser Datenschutzerklärung beschrieben sind.
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>Welche Daten wir erheben: E-Mail-Adressen, OAuth2-Daten, Protokolldaten und weitere Informationen, die Sie aktiv bereitstellen.</li>
                      <li>Warum wir diese Daten erheben: Zur Registrierung für die Beta-Phase, Authentifizierung über Google-Dienste und zur Sicherstellung eines sicheren und stabilen Betriebs.</li>
                      <li>Ihre Rechte: Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und Widerspruch.</li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 3: Grundsätze der Datenverarbeitung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Grundsätze der Datenverarbeitung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Unsere Datenverarbeitung orientiert sich an folgenden Grundsätzen:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Rechtmäßigkeit, Verarbeitung nach Treu und Glauben und Transparenz:</strong> Wir verarbeiten Ihre Daten ausschließlich auf einer rechtlichen Grundlage und informieren Sie transparent über die Verarbeitung.
                      </li>
                      <li>
                        <strong>Zweckbindung:</strong> Die Verarbeitung erfolgt ausschließlich zu den in dieser Datenschutzerklärung beschriebenen Zwecken.
                      </li>
                      <li>
                        <strong>Datenminimierung:</strong> Wir erheben nur die Daten, die für die beschriebenen Zwecke erforderlich sind.
                      </li>
                      <li>
                        <strong>Speicherbegrenzung:</strong> Wir speichern Ihre Daten nur so lange, wie es für die Erfüllung der Zwecke erforderlich ist oder gesetzlich vorgeschrieben ist.
                      </li>
                      <li>
                        <strong>Vertraulichkeit und Sicherheit:</strong> Wir schützen Ihre Daten durch technische und organisatorische Maßnahmen vor unbefugtem Zugriff, Verlust oder Missbrauch.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 4: Technische und organisatorische Maßnahmen */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Technische und organisatorische Maßnahmen
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Zum Schutz Ihrer Daten setzen wir umfassende Sicherheitsmaßnahmen ein:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li><strong>Verschlüsselung:</strong> Datenübertragungen erfolgen ausschließlich über SSL/TLS-verschlüsselte Verbindungen.</li>
                      <li><strong>Zugriffsmanagement:</strong> Nur autorisierte Personen haben Zugang zu personenbezogenen Daten.</li>
                      <li><strong>Datensicherheitsaudits:</strong> Regelmäßige Überprüfung der Datensicherheitsmaßnahmen und der Einhaltung der Datenschutzanforderungen.</li>
                      <li><strong>Redundanz und Backup:</strong> Alle kritischen Daten werden regelmäßig gesichert, um Datenverluste zu verhindern.</li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 5: Erhobene Daten */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Erhobene Daten
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Im Rahmen der Nutzung unserer Website und Dienste erheben und verarbeiten wir folgende personenbezogene Daten:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Daten, die Sie aktiv bereitstellen:</strong>
                        <ul className="list-disc pl-5 space-y-1">
                          <li>E-Mail-Adresse: Erforderlich für die Registrierung zur Warteliste und für Benachrichtigungen im Rahmen der Beta-Phase.</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Daten, die automatisch erfasst werden:</strong>
                        <ul className="list-disc pl-5 space-y-1">
                          <li>IP-Adresse</li>
                          <li>Zeitstempel der Anfrage</li>
                          <li>Browsertyp und Version</li>
                          <li>Betriebssystem</li>
                          <li>Referrer-URL (die Seite, über die Sie auf unsere Website gelangt sind)</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Daten, die im Zusammenhang mit Google-Diensten verarbeitet werden:</strong>
                        <ul className="list-disc pl-5 space-y-1">
                          <li>OAuth2-Daten: Zugriffstokens zur Authentifizierung bei Google-Diensten.</li>
                          <li>Google Drive-Daten: Zugriff auf Dateien, die Sie ausdrücklich freigegeben haben.</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 6: Zweck der Datenverarbeitung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Zweck der Datenverarbeitung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Wir verarbeiten Ihre personenbezogenen Daten ausschließlich für folgende Zwecke:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Registrierung und Verwaltung der Warteliste:</strong> Erhebung und Speicherung Ihrer E-Mail-Adresse, um Ihnen Benachrichtigungen über die Beta-Phase und andere relevante Informationen zu senden.
                      </li>
                      <li>
                        <strong>Authentifizierung und Bereitstellung von Funktionen:</strong> Nutzung von OAuth2 zur sicheren Anmeldung und Authentifizierung.
                      </li>
                      <li>
                        <strong>Verbesserung und Weiterentwicklung unserer Plattform:</strong> Analyse von Nutzungsdaten und Protokolldaten, um technische Probleme zu identifizieren und die Benutzererfahrung zu verbessern.
                      </li>
                      <li>
                        <strong>Sicherstellung des sicheren und stabilen Betriebs:</strong> Verwendung von Protokolldaten zur Abwehr von Angriffen, zur Behebung technischer Probleme und zur Sicherstellung der Verfügbarkeit unserer Dienste.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 7: Datenminimierung und Zweckbindung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Datenminimierung und Zweckbindung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Wir erheben nur die Daten, die für die beschriebenen Zwecke unbedingt erforderlich sind. Eine weitergehende Verarbeitung Ihrer Daten erfolgt nicht ohne Ihre ausdrückliche Zustimmung.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 8: Rechtsgrundlagen der Datenverarbeitung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Rechtsgrundlagen der Datenverarbeitung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Basis der folgenden Rechtsgrundlagen:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Einwilligung (Art. 6 Abs. 1 lit. a DSGVO):</strong> Für die Speicherung und Nutzung Ihrer E-Mail-Adresse zur Registrierung für die Warteliste und den Versand von Benachrichtigungen. Ebenso für die Verarbeitung von OAuth2-Daten und Google Drive-Daten, die Sie ausdrücklich autorisieren.
                      </li>
                      <li>
                        <strong>Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO):</strong> Für die Sicherstellung der Stabilität und Sicherheit unserer Systeme (z. B. durch Protokolldaten) sowie für die Analyse von Nutzungsdaten zur Verbesserung der Plattform.
                      </li>
                    </ul>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>Widerrufsrecht:</strong> Sie können Ihre Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der bis dahin erfolgten Verarbeitung berührt wird. Ein Widerruf kann per E-Mail an <a href="mailto:hallo@aita-solution.de">hallo@aita-solution.de</a> erfolgen.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 9: Nutzung von Google-Diensten */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Nutzung von Google-Diensten
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Für bestimmte Funktionen unserer Plattform nutzen wir Dienste von Google, einschließlich OAuth2 und Google Drive.
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>OAuth2-Authentifizierung:</strong> Zweck: Sichere Anmeldung und Identitätsprüfung. Verarbeitete Daten: Zugriffstokens, die bei Ihrer Anmeldung generiert werden.
                      </li>
                      <li>
                        <strong>Google Drive-Integration:</strong> Zweck: Zugriff auf Dateien, die Sie ausdrücklich autorisiert haben, um die Funktionalität unserer Dienste bereitzustellen. Verarbeitete Daten: Nur die Dateien und Inhalte, die Sie uns zur Verfügung stellen.
                      </li>
                    </ul>
                    <p className="mb-4 text-sm sm:text-base">
                      Wir speichern keine Inhalte von Google Drive dauerhaft auf unseren Servern. Der Zugriff erfolgt nur temporär und beschränkt sich auf die von Ihnen genehmigten Daten.
                    </p>
                    <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google-Datenschutzerklärung</a>
                    <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">API Services User Data Policy</a>
                    <a href="https://support.google.com/android/answer/9431959?hl=de" target="_blank" rel="noopener noreferrer">Geräteeinstellungen</a>
                    <a href="https://support.apple.com/de-de/guide/iphone/iph6c8b8c447/ios" target="_blank" rel="noopener noreferrer">Geräteeinstellungen</a>
                    <a href="https://cloud.google.com/terms/eu-model-contract-clause" target="_blank" rel="noopener noreferrer">EU-Standardvertragsklauseln</a>
                    <a href="https://qper.ai/datenschutz" target="_blank" rel="noopener noreferrer">qper.ai/datenschutz</a>
                  </div>
                </section>
                {/* Abschnitt 10: Schutzmaßnahmen für Google-Daten */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Schutzmaßnahmen für Google-Daten
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Zum Schutz Ihrer Daten, die über Google-Dienste verarbeitet werden, setzen wir folgende Maßnahmen um:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li><strong>Sichere Speicherung von Zugriffstokens:</strong> Zugriffstokens werden verschlüsselt und nur für die Dauer der Nutzung gespeichert.</li>
                      <li><strong>Begrenzter Zugriff:</strong> Nur die notwendigen Berechtigungen werden angefordert, und die Verarbeitung erfolgt ausschließlich für die angegebenen Zwecke.</li>
                      <li><strong>Regelmäßige Überprüfung:</strong> Alle Zugriffsprotokolle und Datenverarbeitungsprozesse werden regelmäßig überprüft, um die Sicherheit zu gewährleisten.</li>
                    </ul>
                    <p><a href="">Google verarbeitet diese Daten gemäß der Google-Datenschutzerklärung.</a></p>
                    <p><a href="https://developers.google.com/terms/api-services-user-data-policy">Die Nutzung von Google Drive-Daten erfolgt gemäß der API Services User Data Policy.</a></p>
                    <p><a href="https://support.google.com/android/answer/9431959?hl=de">Widerruf von Berechtigungen ist in den Geräteeinstellungen möglich (z. B. Android).</a></p>
                    <p><a href="https://support.apple.com/de-de/guide/iphone/iph6c8b8c447/ios">Widerruf von Berechtigungen ist in den Geräteeinstellungen möglich (z. B. iOS).</a></p>
                    <p><a href="https://cloud.google.com/terms/eu-model-contract-clause">Google agiert als Auftragsverarbeiter gemäß den EU-Standardvertragsklauseln.</a></p>
                    <p><a href="https://qper.ai/datenschutz">Die Datenschutzerklärung ist jederzeit auf qper.ai/datenschutz verfügbar.</a></p>
                  </div>
                </section>
                {/* Abschnitt 11: Weitergabe von Daten */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Weitergabe von Daten
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Wir behandeln Ihre personenbezogenen Daten vertraulich und geben diese nur in den folgenden Fällen an Dritte weiter:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Google Cloud:</strong> Zweck: Nutzung von Google-Diensten wie OAuth2 und Google Drive zur Authentifizierung und Bereitstellung unserer Dienste. Status: Google agiert als Auftragsverarbeiter und verarbeitet Ihre Daten gemäß den EU-Standardvertragsklauseln.
                      </li>
                      <li>
                        <strong>Keine Weitergabe an unbefugte Dritte:</strong> Es erfolgt keine Weitergabe Ihrer Daten an andere Unternehmen oder Dritte ohne Ihre ausdrückliche Einwilligung, es sei denn, wir sind gesetzlich dazu verpflichtet (z. B. im Rahmen von Ermittlungen durch Strafverfolgungsbehörden).
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 12: Datenübertragung in Drittländer */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Datenübertragung in Drittländer
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Google-Dienste können Daten in die USA oder andere Drittländer übertragen.
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Schutzmaßnahmen:</strong> Die Verarbeitung erfolgt gemäß den EU-Standardvertragsklauseln, die ein angemessenes Datenschutzniveau garantieren.
                      </li>
                      <li>
                        Weitere Informationen finden Sie in der <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google-Datenschutzerklärung</a>.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 13: Speicherdauer */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Speicherdauer
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Wir speichern Ihre Daten nur so lange, wie es für die jeweiligen Zwecke erforderlich ist, oder bis Sie Ihre Einwilligung widerrufen.
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>E-Mail-Adressen:</strong> Speicherdauer: Bis zum Abschluss der Beta-Phase oder bis Sie Ihre Einwilligung widerrufen.
                      </li>
                      <li>
                        <strong>OAuth2-Tokens:</strong> Speicherdauer: Temporär während der aktiven Nutzung unserer Dienste. Nach Abmeldung werden die Tokens gelöscht.
                      </li>
                      <li>
                        <strong>Google Drive-Daten:</strong> Speicherdauer: Es erfolgt keine dauerhafte Speicherung auf unseren Servern. Die Verarbeitung erfolgt nur während der Nutzung unserer Dienste.
                      </li>
                      <li>
                        <strong>Protokolldaten:</strong> Speicherdauer: Maximal 6 Monate. Diese Daten werden für Sicherheitszwecke und zur Fehleranalyse genutzt.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 14: Löschung Ihrer Daten */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Löschung Ihrer Daten
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Nach Ablauf der jeweiligen Speicherdauer oder nach Widerruf Ihrer Einwilligung werden Ihre Daten vollständig und sicher gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten bestehen.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 15: Ihre Rechte gemäß DSGVO */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Ihre Rechte gemäß DSGVO
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Als betroffene Person haben Sie gemäß der Datenschutz-Grundverordnung (DSGVO) folgende Rechte in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Auskunftsrecht (Art. 15 DSGVO):</strong> Sie haben das Recht, Auskunft über die von uns verarbeiteten personenbezogenen Daten zu erhalten.
                      </li>
                      <li>
                        <strong>Recht auf Berichtigung (Art. 16 DSGVO):</strong> Sie haben das Recht, unrichtige oder unvollständige personenbezogene Daten berichtigen zu lassen.
                      </li>
                      <li>
                        <strong>Recht auf Löschung (Art. 17 DSGVO):</strong> Sie können die Löschung Ihrer Daten verlangen, sofern bestimmte Bedingungen erfüllt sind.
                      </li>
                      <li>
                        <strong>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO):</strong> Sie können die Einschränkung der Verarbeitung verlangen, wenn bestimmte Voraussetzungen vorliegen.
                      </li>
                      <li>
                        <strong>Recht auf Datenübertragbarkeit (Art. 20 DSGVO):</strong> Sie haben das Recht, die von Ihnen bereitgestellten personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
                      </li>
                      <li>
                        <strong>Widerspruchsrecht (Art. 21 DSGVO):</strong> Sie können der Verarbeitung Ihrer Daten widersprechen, wenn die Verarbeitung auf einem berechtigten Interesse basiert.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 16: Widerruf Ihrer Einwilligung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Widerruf Ihrer Einwilligung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Sie können Ihre Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten jederzeit widerrufen. Der Widerruf berührt nicht die Rechtmäßigkeit der Verarbeitung, die bis zum Zeitpunkt des Widerrufs auf Grundlage der Einwilligung erfolgt ist.
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Widerruf richten Sie bitte an:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>E-Mail: <a href="mailto:hallo@aita-solution.de">hallo@aita-solution.de</a></li>
                      <li>Telefon: +49 1575 64 55 908</li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 17: Recht auf Beschwerde bei einer Aufsichtsbehörde */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Recht auf Beschwerde bei einer Aufsichtsbehörde
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Falls Sie der Ansicht sind, dass die Verarbeitung Ihrer Daten gegen die DSGVO verstößt, haben Sie das Recht, eine Beschwerde bei einer Datenschutzaufsichtsbehörde einzureichen.
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      In Deutschland können Sie sich an die Datenschutzbehörde Ihres Bundeslandes oder an den Bundesbeauftragten für den Datenschutz und die Informationsfreiheit wenden.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 18: Änderungen der Datenschutzerklärung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Änderungen der Datenschutzerklärung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Wir behalten uns vor, diese Datenschutzerklärung zu ändern, um sie an geänderte gesetzliche Vorgaben, technische Entwicklungen oder geänderte Geschäftsprozesse anzupassen.
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Aktualisierungen: Die jeweils aktuelle Version der Datenschutzerklärung wird auf unserer Website unter <a href="https://qper.ai/datenschutz" target="_blank" rel="noopener noreferrer">qper.ai/datenschutz</a> veröffentlicht.
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Wesentliche Änderungen, die Ihre Rechte betreffen könnten, werden wir Ihnen per E-Mail mitteilen, sofern Sie uns Ihre E-Mail-Adresse bereitgestellt haben. Wir empfehlen Ihnen, diese Datenschutzerklärung regelmäßig zu überprüfen, um über den aktuellen Stand der Verarbeitung Ihrer Daten informiert zu bleiben.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 19: Kontakt */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Kontakt
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Bei Fragen zur Datenschutzerklärung, zur Verarbeitung Ihrer Daten oder zur Ausübung Ihrer Rechte können Sie uns wie folgt erreichen:
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>Verantwortlicher:</strong><br />
                      AITA Solution - Aymane Amaioua<br />
                      Neugasse 19A<br />
                      65795 Hattersheim am Main<br />
                      Deutschland
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Kontaktmöglichkeiten:<br />
                      E-Mail: <a href="mailto:hallo@aita-solution.de">hallo@aita-solution.de</a><br />
                      Telefon: +49 1575 64 55 908
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Bearbeitungszeitraum: Wir bemühen uns, Ihre Anfragen unverzüglich, spätestens jedoch innerhalb von 30 Tagen, zu beantworten. Sollten wir zusätzliche Informationen von Ihnen benötigen oder Ihre Anfrage komplex sein, informieren wir Sie rechtzeitig über mögliche Verzögerungen.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 20: Schlussbemerkung */}
                <section className="privacy-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Schlussbemerkung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Der Schutz Ihrer personenbezogenen Daten hat für uns oberste Priorität. Wir danken Ihnen für Ihr Vertrauen und versichern Ihnen, dass wir alle notwendigen Maßnahmen ergreifen, um Ihre Daten sicher und DSGVO-konform zu verarbeiten.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {/* Carousel Section */}
          <div className="mt-auto">
            <Carousel />
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
}