import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <div className="relative bottom-2 sm:bottom-4 left-0 right-0 flex justify-center gap-2 sm:gap-4 lg:gap-6 text-white/70 z-10 py-2 sm:py-4">
      <Link 
        to="/terms" 
        className="text-[8px] sm:text-xs lg:text-sm hover:text-white transition-colors duration-200"
      >
        Nutzungsbedingungen
      </Link>
      <Link 
        to="/privacy" 
        className="text-[8px] sm:text-xs lg:text-sm hover:text-white transition-colors duration-200"
      >
        Datenschutzerklärung
      </Link>
    </div>
  );
}