import React from 'react';
import Carousel from '../components/Carousel';
import { Logo } from '../components/Logo';
import { CTAButtons } from '../components/CTAButtons';
import { HeroText } from '../components/HeroText';
import { Footer } from '../components/Footer';
import { GlowEffect } from '../components/GlowEffect';

export default function HomePage() {
  return (
    <div className="min-h-screen overflow-hidden bg-gradient-to-b from-primary via-primary to-[#003A88]">
      <div className="relative flex flex-col min-h-screen">
        <div className="absolute inset-0 pointer-events-none">
          <GlowEffect />
        </div>
        
        <div className="container mx-auto px-4 flex-grow flex flex-col justify-center">
          <Logo />
          <CTAButtons />
          <HeroText />
        </div>

        <div className="mt-auto">
          <Carousel />
        </div>

        <Footer />
      </div>
    </div>
  );
}