import { useState, useEffect } from 'react';
import { validateEmail } from '../utils/validation';
import { GlowEffect } from './GlowEffect';
import { submitToWaitlist } from '../services/api';
import { SuccessMessage } from './SuccessMessage';
import { ErrorMessage } from './ErrorMessage';

interface BetaSignupModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function BetaSignupModal({ isOpen, onClose }: BetaSignupModalProps) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (success) {
      timeoutId = window.setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [success, onClose]);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!validateEmail(email)) {
      setError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
      return;
    }

    setIsSubmitting(true);
    try {
      await submitToWaitlist(email);
      setSuccess(true);
      setEmail('');
    } catch (err) {
      setError('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.');
      console.error('Webhook error:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-primary/80 backdrop-blur-sm" 
        onClick={onClose} 
      />
      
      {/* Modal */}
      <div className="relative w-full max-w-md bg-gradient-to-b from-[#003A88] to-[#002B5B] rounded-xl shadow-2xl overflow-hidden border border-white/10">
        {/* Glow Effect */}
        <div className="absolute inset-0">
          <GlowEffect />
        </div>
        
        {/* Content */}
        <div className="relative z-10 p-6 sm:p-8">
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white/70 hover:text-white transition-colors"
            aria-label="Schließen"
          >
            ✕
          </button>

          {/* Title */}
          <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
            Beta-Tester werden
          </h2>
          
          {/* Description */}
          <p className="text-white/90 text-sm sm:text-base mb-6">
            Seien Sie Teil der Innovation! Registrieren Sie sich jetzt für unsere exklusive Beta-Phase und gestalten Sie mit uns die Zukunft der KI-Assistenz. Wir informieren Sie, sobald ein Platz für Sie verfügbar ist.
          </p>

          {/* Success/Error Messages */}
          {success && (
            <div className="mb-4">
              <SuccessMessage message="Vielen Dank für Ihre Anmeldung! Sie werden in Kürze eine Bestätigung erhalten." />
            </div>
          )}
          
          {error && (
            <div className="mb-4">
              <ErrorMessage message={error} />
            </div>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Ihre E-Mail-Adresse"
                disabled={isSubmitting || success}
                className="w-full px-4 py-3 rounded-lg bg-white/10 border border-white/20 
                         text-white placeholder-white/50 
                         focus:outline-none focus:border-qper-health focus:ring-1 focus:ring-qper-health
                         transition-colors
                         disabled:opacity-50 disabled:cursor-not-allowed"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting || success}
              className="w-full bg-qper-health hover:bg-[#059669] text-white py-3 rounded-lg
                       transition-all duration-200 font-medium 
                       disabled:opacity-70 disabled:cursor-not-allowed
                       hover:scale-105 shadow-lg"
            >
              {isSubmitting ? 'Wird gesendet...' : 'Jetzt registrieren'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}