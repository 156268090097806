interface SuccessMessageProps {
  message: string;
}

export function SuccessMessage({ message }: SuccessMessageProps) {
  return (
    <div className="bg-qper-health/20 border border-qper-health/30 rounded-lg p-4 text-white">
      <p className="text-center">{message}</p>
    </div>
  );
}