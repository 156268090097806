// Import all images
import qperConstruction from '../images/Qper_Construction.jpg';
import qperEducation from '../images/Qper_Education.jpg';
import qperHealth from '../images/Qper_Health.jpg';
import qperLegal from '../images/Qper_Legal.jpg';
import qperMainLogo from '../images/Qper_Main_Logo_Transparent.png';

export const CAROUSEL_IMAGES = [
  { src: qperConstruction, alt: 'Qper Construction' },
  { src: qperEducation, alt: 'Qper Education' },
  { src: qperHealth, alt: 'Qper Health' },
  { src: qperLegal, alt: 'Qper Legal' }
] as const;

export const MAIN_LOGO = qperMainLogo;