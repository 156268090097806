import { TypewriterText } from './TypewriterText';

export function HeroText() {
  return (
    <div className="max-w-4xl mx-auto text-center mb-2 sm:mb-4">
      <div className="mb-2 sm:mb-3">
        <div className="relative inline-block text-center">
          <h1 className="text-xl sm:text-3xl lg:text-4xl font-bold text-white">
            <TypewriterText 
              text="Die Zukunft der Assistenz beginnt hier!"
              delay={80}
            />
          </h1>
        </div>
      </div>
      <div className="space-y-1 sm:space-y-2 text-white">
        <p className="text-sm sm:text-base lg:text-lg">
          Modulare KI, die Ihre Arbeit smarter macht.
        </p>
        <p className="text-xs sm:text-sm lg:text-base text-gray-200">
          Optimiert für Bauwesen, Gesundheit, Bildung, Recht und mehr...
        </p>
      </div>
    </div>
  );
}