import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Logo } from '../components/Logo';
import { GlowEffect } from '../components/GlowEffect';
import Carousel from '../components/Carousel';
import { Footer } from '../components/Footer';

export default function TermsPage() {
  return (
    <div className="min-h-screen overflow-hidden bg-gradient-to-b from-primary via-primary to-[#003A88]">
      <div className="relative flex flex-col min-h-screen">
        <div className="absolute inset-0 pointer-events-none">
          <GlowEffect />
        </div>
        <div className="container mx-auto px-4 py-4 sm:py-6 flex-grow">
          {/* Header */}
          <div className="flex items-center justify-between mb-6 sm:mb-8">
            <Link 
              to="/"
              className="flex items-center text-white/80 hover:text-white transition-colors gap-2"
            >
              <ArrowLeft size={20} />
              <span>Zurück</span>
            </Link>
          </div>
          {/* Content */}
          <div className="max-w-4xl mx-auto mb-8">
            <div className="backdrop-blur-lg rounded-xl p-4 sm:p-6 lg:p-8 shadow-xl border border-white/10">
                                          {/* Logo positioned at top right of content container */}
              <div className="absolute -top-6 -right-9 w-24 h-24 sm:w-28 sm:h-28">
                <Logo />
              </div>
              <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-white mb-4 sm:mb-6">
                Nutzungsbedingungen
              </h1>
              <div className="space-y-6 sm:space-y-8 text-white/90">
                {/* Abschnitt 1: Geltungsbereich */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Geltungsbereich
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Diese Nutzungsbedingungen gelten für:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        Die Nutzung der Website <strong>qper.ai</strong>: Zur Registrierung für die Warteliste der Beta-Phase.
                      </li>
                      <li>
                        Die Nutzung der mobilen App: Einschließlich erweiterter Berechtigungen wie Kamera, Mikrofon, Standortdaten und Google Drive.
                      </li>
                      <li>
                        Die Beta-Phase: In der die App getestet und weiterentwickelt wird, bevor sie offiziell veröffentlicht wird.
                      </li>
                      <li>
                        Die Integration mit Google-Diensten: Nutzung von Google OAuth2 zur Authentifizierung und Google Drive für Dateioperationen.
                      </li>
                      <li>
                        Zukünftige Erweiterungen und Dienste: Diese Nutzungsbedingungen gelten auch für alle zukünftigen Updates, Erweiterungen und zusätzlichen Dienste.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 2: Anbieter */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Anbieter
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Verantwortlich für die Bereitstellung der Dienste ist:
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>AITA Solution - Aymane Amaioua</strong><br />
                      Neugasse 19A<br />
                      65795 Hattersheim am Main<br />
                      Deutschland
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Kontaktmöglichkeiten:<br />
                      E-Mail: <a href="mailto:hallo@aita-solution.de">hallo@aita-solution.de</a><br />
                      Telefon: +49 1575 64 55 908
                    </p>
                  </div>
                </section>
                {/* Abschnitt 3: Leistungen */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Leistungen
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die Website und App bieten folgende Leistungen:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Registrierung:</strong> Nutzer können sich mit einer gültigen E-Mail-Adresse für die Warteliste der Beta-Phase registrieren.
                      </li>
                      <li>
                        <strong>App-Integration mit Google-Diensten:</strong> Sicherer Zugriff auf Google-Dienste, einschließlich Authentifizierung (OAuth2) und Zugriff auf Google Drive-Inhalte.
                      </li>
                      <li>
                        <strong>Erweiterte App-Funktionen:</strong> Zugriff auf Kamera und Mikrofon für spezifische Funktionen sowie standortbasierte Dienste.
                      </li>
                      <li>
                        <strong>Beta-Phase:</strong> Testzugang zur App und ihrer Funktionen, um die App weiterzuentwickeln und zu optimieren.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 4: Registrierung */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Registrierung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>E-Mail-Adresse:</strong> Nutzer müssen eine gültige E-Mail-Adresse angeben, um sich für die Warteliste einzutragen und Benachrichtigungen zur Beta-Phase zu erhalten.
                      </li>
                      <li>
                        <strong>Keine Garantie:</strong> Die Registrierung garantiert keinen Zugang zur Beta-Phase. Der Anbieter entscheidet, welche Nutzer Zugriff erhalten.
                      </li>
                      <li>
                        <strong>Pflichten bei der Registrierung:</strong> Nutzer sind verpflichtet, ihre Daten korrekt und aktuell anzugeben.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 5: Kosten der Nutzung */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Kosten der Nutzung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die Nutzung der Website, App und Beta-Phase ist kostenlos.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 6: Erteilung von Berechtigungen */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Erteilung von Berechtigungen
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die Nutzung bestimmter Funktionen der App erfordert die Erteilung von Berechtigungen durch die Nutzer.
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Kamera und Mikrofon:</strong> Zugriff ist erforderlich für Funktionen wie Foto- und Videoaufnahmen sowie Audioaufzeichnungen. Berechtigungen können jederzeit in den Geräteeinstellungen widerrufen werden.
                      </li>
                      <li>
                        <strong>Standortdaten:</strong> Standortdaten werden für standortbasierte Dienste verarbeitet, sofern der Nutzer seine Zustimmung erteilt hat.
                      </li>
                      <li>
                        <strong>Google Drive:</strong> Der Zugriff erfolgt nur auf die vom Nutzer ausdrücklich freigegebenen Dateien. Es erfolgt keine dauerhafte Speicherung der Inhalte auf den Servern der App.
                      </li>
                      <li>
                        <strong>Warnhinweis zur Berechtigungserteilung:</strong> Nutzer sollten vor der Erteilung von Berechtigungen die Funktionsweise der App prüfen und keine Berechtigungen erteilen, die sie nicht autorisieren.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 7: Pflichten der Nutzer */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Pflichten der Nutzer
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Angabe korrekter Informationen:</strong> Die E-Mail-Adresse und andere erforderliche Daten müssen korrekt und aktuell sein.
                      </li>
                      <li>
                        <strong>Verantwortung für Inhalte:</strong> Nutzer dürfen keine Inhalte bereitstellen, die gegen geltendes Recht, Rechte Dritter oder die Nutzungsrichtlinien von Google verstoßen.
                      </li>
                      <li>
                        <strong>Sicherstellung der Berechtigungskonformität:</strong> Nutzer dürfen Berechtigungen (z. B. Zugriff auf Kamera, Mikrofon, Standortdaten oder Google Drive) nicht missbräuchlich verwenden.
                      </li>
                      <li>
                        <strong>Einhaltung gesetzlicher Vorschriften:</strong> Nutzer sind dafür verantwortlich, die Dienste im Einklang mit den geltenden Gesetzen und Vorschriften zu nutzen.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 8: Widerruf von Berechtigungen */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Widerruf von Berechtigungen
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Berechtigungen wie Zugriff auf Kamera, Mikrofon oder Standortdaten können jederzeit in den Geräteeinstellungen widerrufen werden. Einige Funktionen der App könnten durch den Widerruf eingeschränkt oder nicht verfügbar sein.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 9: Haftung des Anbieters */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Haftung des Anbieters
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Verfügbarkeit:</strong> Der Anbieter bemüht sich, die Website und App möglichst unterbrechungsfrei verfügbar zu machen, übernimmt jedoch keine Garantie für die ständige Verfügbarkeit der Dienste.
                      </li>
                      <li>
                        <strong>Haftungsbeschränkung:</strong> Der Anbieter haftet nur für Schäden, die durch vorsätzliches oder grob fahrlässiges Verhalten verursacht wurden. Keine Haftung besteht für indirekte Schäden, Folgeschäden oder Datenverluste.
                      </li>
                      <li>
                        <strong>Haftung für Drittanbieter-Dienste:</strong> Der Anbieter übernimmt keine Haftung für Fehler oder Datenschutzverletzungen durch Drittanbieter-Dienste wie Google.
                      </li>
                      <li>
                        <strong>Haftungsausschluss für Berechtigungen:</strong> Der Anbieter haftet nicht für Einschränkungen, die durch den Widerruf von Berechtigungen durch den Nutzer entstehen.
                      </li>
                      <li>
                        <strong>Haftung für Datenschutzverletzungen auf Nutzergeräten:</strong> Der Anbieter übernimmt keine Verantwortung für Datenschutzverletzungen, die durch unsichere Geräteumgebungen oder fehlerhafte Konfigurationen entstehen.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 10: Verantwortung der Nutzer */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Verantwortung der Nutzer
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Die ordnungsgemäße Nutzung der App:</strong> Nutzer sind für die korrekte Handhabung der Funktionen verantwortlich, einschließlich der Nutzung von Kamera, Mikrofon, Standortdaten und Google Drive.
                      </li>
                      <li>
                        <strong>Einhaltung gesetzlicher Vorschriften:</strong> Nutzer müssen die Dienste im Einklang mit den geltenden Gesetzen nutzen.
                      </li>
                      <li>
                        <strong>Schäden durch missbräuchliche Nutzung:</strong> Der Anbieter übernimmt keine Haftung für Schäden, die durch falsche Nutzung oder unbefugte Weitergabe von Berechtigungen entstehen.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 11: Gewährleistung */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Gewährleistung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Da es sich um eine Beta-Phase handelt, können Fehler oder Einschränkungen auftreten. Der Anbieter gibt keine Garantie für die vollständige Funktionsfähigkeit oder Fehlerfreiheit der App während der Beta-Phase. Die Nutzung erfolgt auf eigenes Risiko der Nutzer.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 12: Datenschutz */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Datenschutz
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die Verarbeitung personenbezogener Daten erfolgt gemäß der Datenschutzerklärung. 
                      Im Rahmen der App werden folgende Daten verarbeitet:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Kamera und Mikrofon:</strong> Verwendet für Foto-, Video- und Audioaufnahmen im Rahmen der App-Funktionen. Daten werden nur temporär verarbeitet und nicht dauerhaft gespeichert, es sei denn, der Nutzer stimmt ausdrücklich zu.
                      </li>
                      <li>
                        <strong>Standortdaten:</strong> Verarbeitet zur Bereitstellung standortbasierter Dienste. Standortdaten werden nur während der Nutzung der entsprechenden Funktionen und mit Zustimmung des Nutzers verarbeitet.
                      </li>
                      <li>
                        <strong>Google Drive:</strong> Zugriff erfolgt nur auf Dateien, die der Nutzer ausdrücklich autorisiert hat. Es erfolgt keine dauerhafte Speicherung der Inhalte auf den Servern der App.
                      </li>
                      <li>
                        <strong>Widerruf von Berechtigungen:</strong> Nutzer können erteilte Berechtigungen jederzeit in den Geräteeinstellungen widerrufen. Dies kann jedoch die Nutzung bestimmter App-Funktionen einschränken.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 13: Nutzung von Google-Diensten */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Nutzung von Google-Diensten
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die App verwendet die folgenden Google-Dienste:
                    </p>
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>OAuth2:</strong> Verwendet für eine sichere Anmeldung und Authentifizierung.
                      </li>
                      <li>
                        <strong>Google Drive:</strong> Zugriff auf Dateien, die der Nutzer ausdrücklich freigegeben hat. Die Verarbeitung erfolgt temporär und ausschließlich für die vom Nutzer autorisierten Zwecke.
                      </li>
                    </ul>
                    <p className="mb-4 text-sm sm:text-base">
                      Die Nutzung von Google-Diensten unterliegt den folgenden Richtlinien:
                      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                        Google-Datenschutzerklärung
                      </a> und 
                      <a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noopener noreferrer">
                        API Services User Data Policy
                      </a>.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 14: Änderungen der Nutzungsbedingungen */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Änderungen der Nutzungsbedingungen
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Der Anbieter behält sich vor, diese Nutzungsbedingungen jederzeit zu ändern. Änderungen treten mit ihrer Veröffentlichung auf der Website oder in der App in Kraft.
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>Benachrichtigung über wesentliche Änderungen:</strong> Nutzer werden über wesentliche Änderungen der Nutzungsbedingungen per E-Mail informiert, sofern sie ihre E-Mail-Adresse bei der Registrierung angegeben haben.
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Es wird empfohlen, die Nutzungsbedingungen regelmäßig zu überprüfen, um sich über den aktuellen Stand zu informieren.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 15: Kontakt und Support */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Kontakt und Support
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Bei Fragen zu diesen Nutzungsbedingungen, zur Nutzung der Website oder App, oder zur Ausübung von Rechten können Sie den Anbieter wie folgt kontaktieren:
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>AITA Solution - Aymane Amaioua</strong><br />
                      Neugasse 19A<br />
                      65795 Hattersheim am Main<br />
                      Deutschland
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      Kontaktmöglichkeiten:<br />
                      E-Mail: <a href="mailto:hallo@aita-solution.de">hallo@aita-solution.de</a><br />
                      Telefon: +49 1575 64 55 908
                    </p>
                    <p className="mb-4 text-sm sm:text-base">
                      <strong>Bearbeitungszeitraum:</strong> Anfragen werden in der Regel innerhalb von 30 Tagen beantwortet. Sollten komplexe Sachverhalte zusätzliche Zeit erfordern, werden wir Sie entsprechend informieren.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 16: Salvatorische Klausel */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Salvatorische Klausel
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam oder undurchführbar sein, bleibt die Wirksamkeit der übrigen Bestimmungen davon unberührt. Die unwirksame oder undurchführbare Bestimmung wird durch eine rechtlich zulässige Regelung ersetzt, die dem wirtschaftlichen Zweck der ursprünglichen Regelung am nächsten kommt.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 17: Anwendbares Recht und Gerichtsstand */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Anwendbares Recht und Gerichtsstand
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Anwendbares Recht:</strong> Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des internationalen Privatrechts und des UN-Kaufrechts.
                      </li>
                      <li>
                        <strong>Gerichtsstand:</strong> Der ausschließliche Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesen Nutzungsbedingungen ist der Sitz des Anbieters.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 18: Nutzung durch Minderjährige */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Nutzung durch Minderjährige
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Die Nutzung der Website und App ist nur Personen gestattet, die das 16. Lebensjahr vollendet haben. Nutzer unter 16 Jahren dürfen die Dienste nur mit ausdrücklicher Zustimmung eines Erziehungsberechtigten nutzen.
                    </p>
                  </div>
                </section>
                {/* Abschnitt 19: Technische Einschränkungen */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Technische Einschränkungen
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <ul className="list-disc pl-5 space-y-2 text-sm sm:text-base">
                      <li>
                        <strong>Kompatibilität:</strong> Die Nutzung der App erfordert eine kompatible Geräte- und Softwareumgebung. Der Anbieter übernimmt keine Haftung für Einschränkungen, die durch nicht unterstützte Geräte oder Softwareversionen entstehen.
                      </li>
                      <li>
                        <strong>Aktualisierungen:</strong> Nutzer sind dafür verantwortlich, die App auf dem aktuellen Stand zu halten, um von neuen Funktionen und Sicherheitsupdates zu profitieren.
                      </li>
                    </ul>
                  </div>
                </section>
                {/* Abschnitt 20: Schlussbemerkung */}
                <section className="terms-section">
                  <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold text-white mb-3 sm:mb-4">
                    Schlussbemerkung
                  </h2>
                  <div className="prose prose-invert prose-sm sm:prose-base opacity-90">
                    <p className="mb-4 text-sm sm:text-base">
                      Der Anbieter bedankt sich für die Nutzung der Dienste und verpflichtet sich, diese kontinuierlich zu verbessern und die höchsten Standards für Datenschutz und Nutzerfreundlichkeit einzuhalten.
                    </p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {/* Carousel Section */}
          <div className="mt-auto">
            <Carousel />
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
}
