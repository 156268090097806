interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <div className="bg-red-500/20 border border-red-500/30 rounded-lg p-4 text-white">
      <p className="text-center">{message}</p>
    </div>
  );
}