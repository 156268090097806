import { GlowEffect } from './GlowEffect';
import { MAIN_LOGO } from '../constants/images';
import { useLocation } from 'react-router-dom';

export function Logo() {
  const location = useLocation();

  // Prüfen, ob wir auf den Seiten Datenschutzerklärung oder Nutzungsbedingungen sind
  const isSmallLogoPage =
    location.pathname === '/datenschutzerklaerung' || location.pathname === '/nutzungsbedingungen' || location.pathname === '/privacy' || location.pathname === '/terms';

  return (
    <div className="mb-4 sm:mb-6 lg:mb-8">
      <div
        className={`
          relative 
          ${isSmallLogoPage ? 'w-12 h-12 sm:w-16 sm:h-16' : 'w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40'}
          mx-auto
          transition-all duration-700
        `}
      >
        <div className="absolute inset-0 z-10 scale-110">
          <GlowEffect />
        </div>
        <div
          className="w-full h-full relative z-50 animate-logo-pulse"
          style={{
            filter: 'drop-shadow(rgba(0, 10, 6, 0.02) 0px 0px 0.7471px)',
          }}
        >
          <img
            src={MAIN_LOGO}
            alt="Qper AI"
            className="w-full h-full object-contain opacity-100"
          />
        </div>
      </div>
    </div>
  );
}
