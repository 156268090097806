import { useState } from 'react';
import { BetaSignupModal } from './BetaSignupModal';

export function CTAButtons() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="mb-4 sm:mb-6 lg:mb-8">
      <div className="flex justify-center">
        <button 
          onClick={() => setIsModalOpen(true)}
          className="
            bg-qper-health hover:bg-[#059669]
            text-white px-4 sm:px-6 py-2 sm:py-2.5 rounded-lg
            transition-all duration-200 ease-in-out
            font-medium shadow-lg hover:shadow-xl
            backdrop-blur-sm
            text-sm sm:text-base
            w-[80%] sm:w-[220px] lg:w-[240px]
            mx-auto
            hover:scale-105
          "
        >
          Jetzt Beta-Tester werden
        </button>
      </div>

      <BetaSignupModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}