interface WebhookPayload {
  data: {
    email: string;
    metadata: {
      timestamp: string;
      source: string;
    }
  }
}

export async function submitToWaitlist(email: string): Promise<Response> {
  const payload: WebhookPayload = {
    data: {
      email,
      metadata: {
        timestamp: new Date().toISOString(),
        source: "QPER Waitlist"
      }
    }
  };

  // Using no-cors mode to bypass CORS restrictions
  const response = await fetch('https://hooks.zapier.com/hooks/catch/20455237/2s6e1ue/', {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Accept': 'application/json',
    },
    body: JSON.stringify(payload)
  });

  // Since no-cors mode returns an opaque response, we can't check response.ok
  // Instead, we'll assume success if the request didn't throw an error
  return response;
}